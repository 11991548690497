import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to the homepage after 5 seconds
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timer when component unmounts
  }, [navigate]);

  return (
    <div className="payment-success-page">
      <div className="success-message">
        <h1>Payment Successful</h1>
        <p>Thank you for subscribing to our services. Your account will be activated shortly.</p>
        <p>You will be redirected to the homepage in seconds.</p>
        <p>If you are not redirected automatically</p>
        <p><a href="/">click here</a></p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
