import React from 'react';
import './TermsConditions.css';

function TermsConditions() {
  return (
    <div className="terms-container">
      <h1>Terms & Disclaimer</h1>
      <p>Last Updated: December 17, 2024</p>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using CIR-9's website and services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.
        </p>
      </section>

      <section>
        <h2>2. Use of Services</h2>
        <p>
          CIR-9 provides technical analysis tools and market insights to assist with cryptocurrency trading. These tools are intended for informational and educational purposes only and do not constitute financial or investment advice.  
          <strong>Disclaimer:</strong> Trading cryptocurrencies involves substantial risk, including the potential loss of all invested capital. Users are advised to consult with a licensed financial advisor before making any trading decisions.
        </p>
      </section>

      <section>
        <h2>3. User Account</h2>
        <p>
          To access certain features of CIR-9, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and agree to notify us immediately in case of any unauthorized access or suspicious activity.
        </p>
      </section>

      <section>
        <h2>4. Intellectual Property</h2>
        <p>
          All content, software, and materials provided on CIR-9's website are protected by copyright and other intellectual property laws. Users may not reproduce, distribute, modify, or create derivative works based on the content without prior written permission from CIR-9.
        </p>
      </section>

      <section>
        <h2>5. Limitations of Liability</h2>
        <p>
          CIR-9 shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use of our services. This includes, but is not limited to, financial losses, loss of data, or damages resulting from errors or omissions in our tools or insights.
        </p>
      </section>

      <section>
        <h2>6. Disclaimer of Warranties</h2>
        <p>
          CIR-9's services are provided "as is" and "as available," without any warranties or guarantees. We do not warrant that our services will be uninterrupted, error-free, or meet your specific expectations. Users access CIR-9 at their own risk.
        </p>
      </section>

      <section>
        <h2>7. Privacy Policy</h2>
        <p>
          CIR-9 respects your privacy and is committed to protecting your personal data. For details on how we collect, use, and protect your information, please refer to our <a href="/Privacy">Privacy Policy</a>.
        </p>
      </section>

      <section>
        <h2>8. Changes to Terms</h2>
        <p>
          CIR-9 reserves the right to update or modify these Terms and Conditions at any time. We will notify users of significant changes through the website or email. Continued use of our services after such modifications constitutes acceptance of the updated terms.
        </p>
      </section>

    
      
    </div>
  );
}

export default TermsConditions;
