import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase-config';
import './Subscribe.css';

import Spinner from '../Spinner';

const Subscribe = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState('monthly');
  const subscriptionTypeRef = useRef(subscriptionType);
  const navigate = useNavigate();

  // التحقق من حالة تسجيل الدخول للمستخدم
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        //console.log('User is logged in:', currentUser.email);
        setUser(currentUser);

        // التحقق من اشتراك المستخدم في Firestore
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const subscriptionEndDate = new Date(userData.subscriptionEndDate);

          // إذا كان الاشتراك ساريًا، انتقل إلى الصفحة الرئيسية
          if (new Date() < subscriptionEndDate) {
            navigate('/');
          }
        }
      } else {
        //console.log('No user is logged in.');
        setUser(null);
      }
      setLoading(false); // انتهى التحميل
    });
    return () => unsubscribe();
  }, [navigate]);

  // تحديث المرجع عند تغيير نوع الاشتراك
  useEffect(() => {
    subscriptionTypeRef.current = subscriptionType;
  }, [subscriptionType]);

  // تسجيل الدخول باستخدام Google
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      //console.log('Google Login Success:', result.user);
      setUser(result.user); // تعيين بيانات المستخدم بعد تسجيل الدخول
    } catch (error) {
      console.error('Error with Google login: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptionPrice = () => (subscriptionType === 'monthly' ? '19.00' : '159.00');

  const handlePaymentSubmission = () => {
    if (!user) {
      alert("Please log in to proceed with payment.");
      return;
    }

    const subscriptionPrice = getSubscriptionPrice();
    const userUid = user.uid; // Get the Firebase UID of the user

    const data = {
      cmd: "_pay_simple",
      merchant: "cdec740d13583f2506ae63e0e0d3de87", // Replace with your CoinPayments merchant ID
      item_name: "Premium Subscription",
      item_desc: subscriptionType, // Monthly or Yearly
      currency: "USD",
      amountf: subscriptionPrice,
      custom: userUid, // Send UID in custom field for verification in the server
      success_url: "https://your-website.com/success", // Your success URL
      cancel_url: "https://your-website.com/subscribe", // Your cancel URL
    };

    // Send data to CoinPayments
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://www.coinpayments.net/index.php"; // CoinPayments IPN URL

    Object.keys(data).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="subscribe-page">
      <h1>Subscribe to Premium</h1>

      <p className="disclaimer">
            Disclaimer: Trading cryptocurrencies carries a high level of risk. Please trade responsibly. Learn more in our <a href="/TermsConditions">Terms & Disclaimer</a>.
          </p>

      {!user ? (
        <div>
          <p>You need to log in to subscribe.</p>
          <button onClick={handleGoogleLogin}>Login with Google</button>
        </div>
      ) : (
        <>
          <p>Choose your subscription plan:</p>
          <div className="subscription-options">
            <input
              type="radio"
              id="monthly"
              name="subscription"
              value="monthly"
              checked={subscriptionType === 'monthly'}
              onChange={() => setSubscriptionType('monthly')}
            />
            <label htmlFor="monthly">Monthly - $19.00</label>

            <input
              type="radio"
              id="yearly"
              name="subscription"
              value="yearly"
              checked={subscriptionType === 'yearly'}
              onChange={() => setSubscriptionType('yearly')}
            />
            <label htmlFor="yearly">Yearly - $159.00</label>
          </div>

          {/* Hidden submit button */}
          <button onClick={handlePaymentSubmission} style={{ display: 'none' }}>Proceed to Payment</button>

          {/* Display the payment button as an image with no background */}
          <form method="POST" action="https://www.coinpayments.net/index.php">
            <input type="hidden" name="cmd" value="_pay_simple" />
            <input type="hidden" name="merchant" value="cdec740d13583f2506ae63e0e0d3de87" />
            <input type="hidden" name="item_name" value="Premium Subscription" />
            <input type="hidden" name="item_desc" value={subscriptionType} />
            <input type="hidden" name="currency" value="USD" />
            <input type="hidden" name="amountf" value={getSubscriptionPrice()} />
            <input type="hidden" name="custom" value={user ? user.uid : ''} />
            <input type="hidden" name="success_url" value="https://cir-9.com/payment-success" />
            <input type="hidden" name="cancel_url" value="https://cir-9.com/subscribe" />
            {/* The image button without background */}
            <button type="submit" style={{ background: 'none', border: 'none', padding: 0 }}>
              <img src="https://www.coinpayments.net/images/pub/buynow-wide-blue.png" alt="Pay Now" />
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default Subscribe;
