import React, { useState, useEffect } from "react";
import { signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase-config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { FaGoogle, FaSignOutAlt } from "react-icons/fa";
import "./Navbar.css";

const Navbar = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // مراقبة حالة تسجيل الدخول
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // المستخدم مسجل دخول
        setShowModal(false); // لا داعي لعرض المودال عند الدخول
      } else {
        setUser(null); // المستخدم غير مسجل دخول
        setShowModal(true); // عرض المودال عند فتح الموقع
      }
    });

    return () => unsubscribe(); // تنظيف عند إلغاء الاشتراك
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const newUser = result.user;

      if (!newUser) {
        console.error("User authentication failed");
        return;
      }

      const userId = newUser.uid;
      const userRef = doc(db, `users/${userId}`);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        const subscriptionEndDate = new Date();
        subscriptionEndDate.setDate(subscriptionEndDate.getDate() + 7);

        await setDoc(userRef, {
          isSubscribed: true,
          subscriptionEndDate: subscriptionEndDate.toISOString(),
          lastPaymentAmount: 0,
          lastPaymentCurrency: "free",
          lastPaymentDate: new Date().toISOString(),
        });
      }

      setUser(newUser);
      setShowModal(false); // إخفاء المودال بعد تسجيل الدخول
    } catch (error) {
      console.error("Error during Google login:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setShowModal(true); // عرض المودال مجددًا بعد تسجيل الخروج
    } catch (error) {
      console.error("Error with logout:", error);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setShowModal(false);
    }
  };

  return (
    <nav className="navbar">
      <a href="/" className="brand-logo">CIR-9</a>
      <ul className="nav-links">
        <li><a href="/">Home</a></li>
        <li><a href="/services">Services</a></li>
        <li><a href="/indicators">Indicators</a></li>
        <li><a href="/about">About Us</a></li>
        <li><a href="/contact">Contact Us</a></li>
      </ul>
      <button
        className="register-btn"
        onClick={() => setShowModal(true)}
        disabled={loading}
      >
        {loading ? "Loading..." : user ? user.displayName : "Register for Free Trial"}
      </button>

      {showModal && (
        <div className="modal" onClick={handleOutsideClick}>
          <div className="modal-content">
            {!user ? (
              <>
                <h2>Welcome</h2>
                <p>
                  Log in to enjoy our exclusive features and start your 7-day free trial.
                  <br />
                  <span className="no-payment-required">No payment details required</span>
                </p>
                <button className="google-login-btn" onClick={handleGoogleLogin}>
                 Log in with Google
                </button>
              </>
            ) : (
              <>
                <h2>Hello, {user.displayName}</h2>
                <p>You can switch accounts or log out from here.</p>
                <button className="logout-btn" onClick={handleLogout}>
                  <FaSignOutAlt />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
