import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CryptoDashboard.css';
import Spinner from './Spinner';


import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase-config';



const CryptoDashboard = () => {
    const [cryptoData, setCryptoData] = useState({});
    const [topCryptos, setTopCryptos] = useState([]);
    const [allCryptoData, setAllCryptoData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Initially set to true
    const [loadingalldata, setLoadingAllData] = useState(true); // Initially set to 
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);

    const [isSubscribed, setIsSubscribed] = useState(true); ////////////////////////////////////////PPPPPPPPPPPPP111111111111111
    const [statusMessage, setStatusMessage] = useState('Checking subscription...');


    


    const coinLogos = {
        BTC: require('./assets/images/BTC.png'),
        ETH: require('./assets/images/ETH.png'),
        XRP: require('./assets/images/XRP.png'),
        BCH: require('./assets/images/BCH.png'),
        MKR: require('./assets/images/MKR.png'),
        FTM: require('./assets/images/FTM.png'),
        FET: require('./assets/images/FET.png'),
        CHZ: require('./assets/images/CHZ.png'),
        SAND: require('./assets/images/SAND.png'),
        SNX: require('./assets/images/SNX.png'),
        CRV: require('./assets/images/CRV.png'),
        SUSHI: require('./assets/images/SUSHI.png'),
        OMG: require('./assets/images/OMG.png'),
        SOL: require('./assets/images/SOL.png'),
        SHIB: require('./assets/images/SHIB.png'),
        WBTC: require('./assets/images/WBTC.png'),
        ETC: require('./assets/images/ETC.png'),
        LDO: require('./assets/images/LDO.png'),
        "1INCH": require('./assets/images/1INCH.png'),
        ETHW: require('./assets/images/ETHW.png'),
        DOGE: require('./assets/images/DOGE.png'),
        ADA: require('./assets/images/ADA.png'),
        TRX: require('./assets/images/TRX.png'),
        DOT: require('./assets/images/DOT.png'),
        LINK: require('./assets/images/LINK.png'),
        NEAR: require('./assets/images/NEAR.png'),
        UNI: require('./assets/images/UNI.png'),
        MATIC: require('./assets/images/MATIC.png'),
        LTC: require('./assets/images/LTC.png'),
        LEO: require('./assets/images/LEO.png'),
        ATOM: require('./assets/images/ATOM.png'),
        XLM: require('./assets/images/XLM.png'),
        ARB: require('./assets/images/ARB.png'),
        FLOKI: require('./assets/images/FLOKI.png'),
        ALGO: require('./assets/images/ALGO.png'),
        GALA: require('./assets/images/GALA.png'),
        AXS: require('./assets/images/AXS.png'),
        APE: require('./assets/images/APE.png'),
        AAVE: require('./assets/images/AAVE.png'),
        BOSON: require('./assets/images/BOSON.png'),
        RLY: require('./assets/images/RLY.png'),
        JUP: require('./assets/images/JUP.png'),
        MLN: require('./assets/images/MLN.png'),
        NEXO: require('./assets/images/NEXO.png'),
        OGN: require('./assets/images/OGN.png'),
    };
    

    

    const hasValidBTCData = (data) => {
        // Check if each value in `data` is either a non-NaN number or a non-empty string
        return Object.values(data).every(value => {
            return (typeof value === 'number' && !isNaN(value)) || (typeof value === 'string' && value.trim() !== '');
        });
    };

    useEffect(() => {
        //console.log('Starting subscription check...');
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                //console.log('User is logged in:', user.email);
                setStatusMessage(`User logged in: ${user.email}`);
    
                try {
                    // استدعاء البيانات من Firestore
                    const userRef = doc(db, 'users', user.uid);
                    const userSnap = await getDoc(userRef);
    
                    if (userSnap.exists()) {
                        //console.log('User data found in Firestore:', userSnap.data());
                        const userData = userSnap.data();
    
                        const currentDate = new Date();
                        const subscriptionEndDateStr = userData.subscriptionEndDate;
    
                        if (!subscriptionEndDateStr) {
                            throw new Error('Subscription end date is not defined.');
                        }
    
                        // تحويل string إلى كائن Date
                        const subscriptionEndDate = new Date(subscriptionEndDateStr);
    
                        // تحقق من صلاحية الاشتراك
                        const isUserSubscribed =
                            userData.isSubscribed && subscriptionEndDate > currentDate;
                        //console.log('Subscription status:', isUserSubscribed ? 'Active' : 'Inactive');
                        //////////////////////////////////////////pppppppppppppppppppppppppppp2222222222222222222222setIsSubscribed(isUserSubscribed);
    
                        setStatusMessage(
                            isUserSubscribed
                                ? 'Subscription is active. Welcome to the premium features!'
                                : 'Subscription is inactive. Please subscribe to access premium features.'
                        );
                    } else {
                        //console.log('No user data found in Firestore for UID:', user.uid);
                        setStatusMessage('No subscription data found. Please subscribe.');
                    }
                } catch (error) {
                    console.error('Error fetching subscription data:', error);
                    setStatusMessage('An error occurred while checking subscription.');
                }
            } else {
                //console.log('No user is currently logged in.');
                setStatusMessage('Please log in to check subscription status.');
                /////////////////////////////////////////////////////ppppppppppppppppppppppppppppp333333333333333333333333333setIsSubscribed(false);
            }
            //setLoading(false);
        });
    
        return () => {
            // تنظيف المراقب عند تفكيك المكون
            unsubscribe();
        };
    }, []);
    
    

    useEffect(() => {
        const fetchBTCData = async () => {
            try {
                const btcResponse = await axios.get(process.env.REACT_APP_BTC_API_URL);
                const btcData = btcResponse.data["BTC-USD"];
                setCryptoData(btcData);
    
                if (hasValidBTCData(btcData)) {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching BTC data:', error);
            }
        };
    
        const fetchAllData = async () => {
            if (!isSubscribed) return; // تحقق من الاشتراك أولاً
            try {
                const allDataResponse = await axios.get(process.env.REACT_APP_ALL_DATA_API_URL);
                const allData = allDataResponse.data;
                setAllCryptoData(allData);
    
                if (hasValidBTCData(allData["BTC-USD"])) {
                    setLoadingAllData(false);
                }
            } catch (error) {
                console.error('Error fetching all crypto data:', error);
            }
        };
    
        // استدعاء fetchBTCData دائمًا
        fetchBTCData();
    
        // استدعاء fetchAllData إذا كان المستخدم مشتركًا
        if (isSubscribed) {
            fetchAllData();
        }
    
        // تحديث البيانات بشكل دوري
        const intervalId = setInterval(() => {
            document.body.classList.add('full-screen-updating');
    
            // تحديث fetchBTCData دائمًا
            fetchBTCData();
    
            // تحديث fetchAllData فقط إذا كان المستخدم مشتركًا
            if (isSubscribed) {
                fetchAllData();
            }
    
            setTimeout(() => {
                document.body.classList.remove('full-screen-updating');
            }, 4000);
        }, 70000);
    
        return () => clearInterval(intervalId);
    }, [isSubscribed]); // إضافة isSubscribed كاعتماد للتحكم في fetchAllData
    

    

   


    
    // Filter and sort the cryptocurrency data based on user input
    const filteredCryptoData = Object.keys(allCryptoData).filter(cryptoKey => {
        const coin = cryptoKey.split('-')[0];
        return coin.toLowerCase().includes(filterText.toLowerCase());
    });

    const sortedCryptoData = filteredCryptoData.sort((a, b) => {
        const coinA = a.split('-')[0];
        const coinB = b.split('-')[0];
        const buyPercentageA = allCryptoData[a][`Buy_Percentage_${coinA}`] || 0;
        const buyPercentageB = allCryptoData[b][`Buy_Percentage_${coinB}`] || 0;
        return buyPercentageB - buyPercentageA;
    });

    const totalPages = Math.ceil(sortedCryptoData.length / itemsPerPage);
    const paginatedData = sortedCryptoData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const getTickerText = () => {
        if (!allCryptoData) return '';
        
        return Object.keys(allCryptoData).map(key => {
            const coin = key.split('-')[0]; // Extract the coin name
            // Use "loading" if the value is NaN or undefined
            const closePrice = isNaN(allCryptoData[key][`Close_${coin}`]) ? 0 : allCryptoData[key][`Close_${coin}`];
            const buyPercentage = isNaN(allCryptoData[key][`Buy_Percentage_${coin}`]) ? 0 : allCryptoData[key][`Buy_Percentage_${coin}`];
            const sellPercentage = isNaN(allCryptoData[key][`Sell_Percentage_${coin}`]) ? 0 : allCryptoData[key][`Sell_Percentage_${coin}`];
            
            // Determine color based on buy and sell percentages
            const color = buyPercentage > sellPercentage ? '#4caf50' : '#f44336'; // Using hex color codes
    
            return (
                <span key={key} style={{ color: color, marginRight: '20px' }}>
                    {<img src={coinLogos[coin]} alt={`${coin} logo`} className="coin-logo" />} 
                    {closePrice === 0 ? closePrice : Number(closePrice).toLocaleString('en-US', { 
                        minimumFractionDigits: closePrice < 0.1 ? 7 : 0, 
                        maximumFractionDigits: closePrice < 0.1 ? 7 : 2 
                    })}$ 
                </span>
            );
        });
    };
    
    // حالة لتخزين العنصر المضغوط
  const [selectedSquare, setSelectedSquare] = useState(null);
  
  // دالة لفتح الشاشة العائمة
  const handleSquareClick = (square) => {
    setSelectedSquare(square);
  };

  // دالة لإغلاق الشاشة العائمة
  const closeModal = () => {
    setSelectedSquare(null);
  };

  

    


    return (
        <div className="dashboard">
            {/* Display spinner at the top if loading */}
            
            
            {loading && <Spinner />}


            {/* Conditionally render the rest of the content */}
            {!loading && (
                <>
                    <div className="ticker">
                        <marquee behavior="scroll" direction="right">
                             .{getTickerText()}.
                        </marquee>
                    </div>

                    <div>
                    <p> </p>
                        <h2-base>Artificial Intelligence Decision Based on Technical Indicators</h2-base>
                        
                        </div>

                    <div className="squares-container-base">
                            <div className="square-base-btc">
                                <h2-base>
                                <img
                                    src={require('./assets/images/BTC.png')}
                                    alt="BTC Logo"
                                    className="btc-logo"
                                />
                            
                                </h2-base>
                            
                            <div className="value-container-base">
                            <span>{Number(cryptoData.Close_BTC).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}$</span>

                            </div>
                            </div>

                            <div className="square-base buy" onClick={() => handleSquareClick("Buy")}>
                            <h2-base>Buy Signals</h2-base>
                            <div className="value-container-base">
                                <span>{Number(cryptoData.Buy_Percentage_BTC).toFixed(2)}%</span>
                            </div>
                            </div>

                            <div className="square-base sell" onClick={() => handleSquareClick("Sell")}>
                            <h2-base>Sell Signals</h2-base>
                            <div className="value-container-base">
                                <span>{Number(cryptoData.Sell_Percentage_BTC).toFixed(2)}%</span>
                            </div>
                            </div>

                            <div className="square-base hold" onClick={() => handleSquareClick("Hold")}>
                            <h2-base>Hold Signals</h2-base>
                            <div className="value-container-base">
                                <span>{Number(cryptoData.Hold_Percentage_BTC).toFixed(2)}%</span>
                            </div>
                            </div>
                    </div>

                    <div className="squares-container">
                            <div className="square" onClick={() => handleSquareClick("Market")}>
                            <h2>Market</h2>
                            <p>{cryptoData.Market_Condition_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("MACD")}>
                            <h2>MACD</h2>
                            <p>{cryptoData.MACD_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("RSI")}>
                            <h2>RSI</h2>
                            <p>{cryptoData.RSI_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("OBV")}>
                            <h2>OBV</h2>
                            <p>{cryptoData.OBV_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("EMA")}>
                            <h2>EMA</h2>
                            <p>{cryptoData.EMA_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("BB")}>
                            <h2>BB</h2>
                            <p>{cryptoData.BB_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("VWAP")}>
                            <h2>VWAP</h2>
                            <p>{cryptoData.VWAP_Decision_BTC}</p>
                            </div>
                            <div className="square" onClick={() => handleSquareClick("ADX")}>
                            <h2>ADX</h2>
                            <p>{cryptoData.ADX_Decision_BTC}</p>
                            </div>
                    </div>

                    
                    
                    
                    {/* الشاشة العائمة */}
                    {selectedSquare && (
                        <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-button" onClick={closeModal}>X</button>
                            <h2>{selectedSquare}</h2>
                            <p>
                                {selectedSquare === "Buy" && (
                                    <>
                                    <p>The Percentage of Technical Indicators Signaling a Buy for Bitcoin</p>
                                    <p>{Number(cryptoData.Buy_Percentage_BTC).toFixed(2)}%</p>
                                    </>
                                )}
                                {selectedSquare === "Sell" && (
                                    <>
                                    <p>The Percentage of Technical Indicators Signaling a Sell for Bitcoin</p>
                                    <p>{Number(cryptoData.Sell_Percentage_BTC).toFixed(2)}%</p>
                                    </>
                                )}
                                {selectedSquare === "Hold" && (
                                    <>
                                    <p>The Percentage of Technical Indicators Signaling a Hold for Bitcoin</p>
                                    <p>{Number(cryptoData.Hold_Percentage_BTC).toFixed(2)}%</p>
                                    </>
                                )}
                                {selectedSquare === "Market" && (
                                    <>
                                    <p>Market Condition</p>
                                    <p>{cryptoData.Market_Condition_BTC}</p>
                                    </>
                                )}
                                {selectedSquare === "MACD" && (
                                    <>
                                    <p>MACD Decision</p>
                                    <p>{cryptoData.MACD_Decision_BTC}</p>
                                    
                                    </>
                                )}
                                {selectedSquare === "RSI" && (
                                    <>
                                    <p>RSI Decision</p>
                                    <p>{cryptoData.RSI_Decision_BTC}</p>
                                    <p>{Math.floor(cryptoData.RSI_14_BTC)}</p>
                                    </>
                                )}
                                {selectedSquare === "OBV" && (
                                    <>
                                    <p>OBV Decision</p>
                                    <p>{cryptoData.OBV_Decision_BTC}</p>
                                    </>
                                )}
                                {selectedSquare === "EMA" && (
                                    <>
                                    <p>EMA Decision</p>
                                    <p>{cryptoData.EMA_Decision_BTC}</p>
                                    <p>EMA9:  { Math.floor(cryptoData.EMA_9_BTC)} </p>
                                    <p>EMA21: {Math.floor(cryptoData.EMA_21_BTC)}</p>
                                    </>
                                )}
                                {selectedSquare === "BB" && (
                                    <>
                                    <p>Bollinger Bands Decision</p>
                                    <p>{cryptoData.BB_Decision_BTC}</p>
                                    <p>High: {Math.floor(cryptoData.BB_High_BTC)}</p>
                                    <p>Low: {Math.floor(cryptoData.BB_Low_BTC)}</p>
                                    </>
                                )}
                                {selectedSquare === "VWAP" && (
                                    <>
                                    <p>VWAP Decision</p>
                                    <p>{cryptoData.VWAP_Decision_BTC}</p>
                                    <p>VWAP: {Math.floor(cryptoData.VWAP_BTC)} </p>
                                    <p>BTC: {Math.floor(cryptoData.Close_BTC)}</p>
                                    </>
                                )}
                                {selectedSquare === "ADX" && (
                                    <>
                                    <p>ADX Decision</p>
                                    <p>{cryptoData.ADX_Decision_BTC}</p>
                                    <p>Trend: {cryptoData.Trend_14_BTC}</p>
                                    <p>Trend Strength: {Math.floor(cryptoData.ADX_14_BTC)} </p>
                                    </>
                                )}
                                </p>

                        </div>
                        </div>
                    )}




                                        {/* Additional Crypto Data Table */}
                                        <div className="data-table">
                {/* حقل الفلترة */}
                

                {/* عرض المحتوى فقط للمشتركين */}
                {isSubscribed ? (
                    loadingalldata ? (
                    <Spinner />
                    ) : (


                        
                    <div className="table-responsive">

                    <input
                    type="text"
                    placeholder="Filter By Coin Name"
                    value={filterText}
                    onChange={(e) => {
                    setFilterText(e.target.value);
                    setCurrentPage(1); // إعادة تعيين الصفحة إلى الأولى عند التصفية
                    }}
                    className="filter-input"
                    />
                        <table>
                        <thead>
                            <tr>
                            <th>Coin</th>
                            <th>Buy Indicators Signals</th>
                            <th>Sell Indicators Signals</th>
                            <th>Hold Indicators Signals</th>
                            <th>Price</th>
                            <th>72-Hour Volatility</th>
                            <th>72-Hour Change</th>
                            <th>14-Hour Change</th>
                            <th>9 - Hour ATR</th>
                            <th>9 - Hour RSI</th>
                            <th>MACD Decision</th>
                            <th>RSI Decision</th>
                            <th>OBV Decision</th>
                            <th>EMA Decision</th>
                            <th>BB Decision</th>
                            <th>VWAP Decision</th>
                            <th>ADX Decision</th>
                            <th>Market Condition</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((cryptoKey) => {
                            const crypto = allCryptoData[cryptoKey];
                            const coin = cryptoKey.split('-')[0];
                            return (
                                <tr key={cryptoKey}>
                                <td className="coin-cell">
                                    <img src={coinLogos[coin]} alt={`${coin} logo`} className="coin-logo" />
                                    <span className="coin-name">{coin}</span>
                                </td>
                                <td>{Number(crypto[`Buy_Percentage_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`Sell_Percentage_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`Hold_Percentage_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`Close_${coin}`]).toLocaleString('en-US', { 
                                            minimumFractionDigits: crypto[`Close_${coin}`] < 0.1 ? 7 : 0, 
                                            maximumFractionDigits: crypto[`Close_${coin}`] < 0.1 ? 7 : 2 
                                        })}$
                                </td>
                                <td>{crypto[`Change_72h_Order_${coin}`] || 0} %</td>
                                <td>{Number(crypto[`Change_72h_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`Change_14h_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`ATR_14_${coin}`]).toFixed(2) || 0} %</td>
                                <td>{Number(crypto[`RSI_14_${coin}`]).toFixed(2) || 0}</td>
                                <td>{crypto[`MACD_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`RSI_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`OBV_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`EMA_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`BB_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`VWAP_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`ADX_Decision_${coin}`] || 0}</td>
                                <td>{crypto[`Market_Condition_${coin}`] || 0}</td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </table>

                        {/* Pagination controls */}
                        <div className="pagination">
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                            Next
                        </button>
                        </div>
                    </div>
                    )
                ) : (
                    <div className="subscribe-message">
                    <h2>Technical Indicator Results For Top Cryptocurrencies Like ETH, SOL, XRP, And More.</h2>
                    <button onClick={() => (window.location.href = '/subscribe')}>Subscribe Now</button>
                    </div>
                )}
                </div>

                </>
            )}
        </div>
    );
};

export default CryptoDashboard;
