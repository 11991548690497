import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import CryptoDashboard from './CryptoDashboard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Services from './pages/Services';
import Indicators from './pages/Indicators';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/TermsConditions';
import Subscribe from './pages/Subscribe';
import Suc from './pages/PaymentSuccess';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';


function App() {
  return (
    
      
        <Router>
          <ScrollToTop />
          <Navbar />

          <Routes>
            <Route path="/" element={<CryptoDashboard />} />
            <Route path="/cir9bucket" element={<CryptoDashboard />} />
            <Route path="/Home" element={<CryptoDashboard />} />
            <Route path="/services" element={<Services />} />
            <Route path="/indicators" element={<Indicators />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/payment-success" element={<Suc />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <Footer />
        </Router>
      
    
  );
}

export default App;
